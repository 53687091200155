import React from 'react';
import Home from "./pages/home/home";
import './App.css';


const App = () => {

    return (
        <Home/>
    );
}

export default App;